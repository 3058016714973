import { gql } from '../../../../hooks'

export const LIST_CLIENTS = gql`
  query ListClients($id: ID!) {
    i1ClientsByLaboratory(id: $id) {
    }
  }
`

export const LIST_SITES = gql`
  query ListSites($id: ID!) {
    i1SitesByClient(id: $id) {
    }
  }
`

export const LIST_MACHINE_TYPES = gql`
  query ListMachineTypesBySite($id_site: ID!) {
    i1MachineTypesBySite(id_site: $id_site) {
    }
  }
`

export const LIST_MACHINES = gql`
  query ListMachinesBySiteMachineType($id_site: ID!, $id_machine_type: ID) {
    i1MachinesBySiteMachineType(
      id_site: $id_site
      id_machine_type: $id_machine_type) {
    }
  }
`

export const CREATE_INSPECTION = gql`
  mutation CreateInspection($id_machine: ID!) {
    i1CreateInspection(id_machine: $id_machine) {
    }
  }
`
