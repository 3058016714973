import { gql } from '../../../../hooks'

export const LIST_SYSTEMS = gql`
  query ListSystems($id_inspection: ID!) {
    i1SystemsByInspection(id_inspection: $id_inspection) {
    }
  }
`

export const LIST_SUB_SYSTEMS = gql`
  query ListSubSystems($id_system: ID!) {
    i1SubSystemsBySystem(id_system: $id_system) {
    }
  }
`

export const ADD_INSPECTION_ENTRY = gql`
  mutation AddInspectionEntry($id_inspection: ID!, $observation: String, $id_system: ID!, $id_sub_system: ID, $criticality: String!, $photos: [ID!]!) {
    i1AddInspectionEntry(
      id_inspection: $id_inspection,
      observation: $observation,
      id_system: $id_system,
      id_sub_system: $id_sub_system,
      criticality: $criticality,
      photos: $photos) {
    }
  }
`

export const PUT_INSPECTION_PHOTO = gql`
  mutation PutInspectionPhoto($photo: String!, $id_inspection: ID!) {
    i1PutInspectionPhoto(id_inspection: $id_inspection, photo: $photo) {
    }
  }
`

export const SPEECH_TO_TEXT = gql`
  mutation SpeechToText($id_inspection: ID!, $file: Upload!) {
    i1SpeechToText(id_inspection: $id_inspection, file: $file) {
    }
  }
`
