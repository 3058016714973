import React from 'react'

// Hooks
import { useMutation, useState, useGlobal } from '../../../../hooks'

// Components
import UIPanelInfo from '../../../UI/Panel/Info'
import UIPanelInfoBlock from '../../../UI/Panel/Info/Block'
import UIPanelInfoSeparation from '../../../UI/Panel/Info/Separation'
import UIButton from '../../../UI/Button'
import UIForm from '../../../UI/FormReload'
import UIFormInputSelect from '../../../UI/FormReload/Input/Select'

// Queries
import {
  LIST_CLIENTS,
  LIST_SITES,
  LIST_MACHINE_TYPES,
  LIST_MACHINES,
  CREATE_INSPECTION,
} from './queries'

const InspectionsFieldInfo = ({ setShowCreateForm }) => {
  const [{ me }] = useGlobal()

  const [idClient, setIdClient] = useState()
  const [idSite, setIdSite] = useState()
  const [idMachineType, setIdMachineType] = useState()

  // --------------------------------------------------------------------------
  //
  // Query: Received samples info
  //
  // --------------------------------------------------------------------------

  const [createInspection] = useMutation(CREATE_INSPECTION, {
    fetchPolicy: 'no-cache',
    onSuccess: () => setShowCreateForm(false),
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  const handleClientChange = (event) => {
    setIdClient(parseInt(event.target.value) || undefined)
    setIdSite()
  }

  const handleSiteChange = (event) => {
    setIdSite(parseInt(event.target.value) || undefined)
  }

  const handleMachineTypeChange = (event) => {
    setIdMachineType(parseInt(event.target.value))
  }

  const handleSubmit = async ({ id_machine }) => {
    await createInspection({ variables: { id_machine } })
  }

  return (
    <UIPanelInfo>
      <UIPanelInfoBlock title="Nueva inspección">
        <UIForm onSubmit={handleSubmit}>
          <UIFormInputSelect
            label="Cliente"
            name="id_client"
            query={LIST_CLIENTS}
            variables={{ id: me.id_laboratory }}
            onChange={handleClientChange}
          >
            <option value={0}>Seleccione un cliente</option>
          </UIFormInputSelect>
          <UIFormInputSelect
            label="Faena"
            name="id_site"
            query={LIST_SITES}
            variables={{ id: idClient }}
            onChange={handleSiteChange}
            disabled={!idClient}
          >
            <option value={0}>Seleccione una faena</option>
          </UIFormInputSelect>
          <UIFormInputSelect
            label="Tipo de equipo"
            name="id_machine_type"
            query={LIST_MACHINE_TYPES}
            variables={{ id_client: idClient, id_site: idSite }}
            onChange={handleMachineTypeChange}
            disabled={!idClient || !idSite}
          >
            <option value={0}>Seleccione un tipo de equipo</option>
          </UIFormInputSelect>
          <UIFormInputSelect
            label="Equipo"
            name="id_machine"
            query={LIST_MACHINES}
            variables={{
              id_client: idClient,
              id_site: idSite,
              id_machine_type: idMachineType,
            }}
            disabled={!idClient || !idSite}
            required
          >
            <option value={0}>Seleccione un equipo</option>
          </UIFormInputSelect>
          <UIPanelInfoSeparation className="my-1" />
          <div className="mr-2 text-center">
            <UIButton
              type="submit"
              variant="info"
              className="mr-2 px-3"
              title="Filtrar"
            >
              Crear inspección
            </UIButton>
          </div>
        </UIForm>
      </UIPanelInfoBlock>
    </UIPanelInfo>
  )
}

export default InspectionsFieldInfo
