import React from 'react'
import { Badge, Row, Col, Image } from 'react-bootstrap'
import Webcam from 'react-webcam'
// import { AudioRecorder } from 'react-audio-voice-recorder'

import { Link } from 'react-router-dom'

// Hooks
import {
  useTrigger,
  useMutation,
  useState,
  useAlert,
  useGlobal,
  useRef,
  useCallback,
} from '../../../../hooks'

// Components
import UIPanelInfo from '../../../UI/Panel/Info'
import UIPanelInfoBlock from '../../../UI/Panel/Info/Block'
import UIPanelInfoSeparation from '../../../UI/Panel/Info/Separation'
import UIButton from '../../../UI/Button'
import UIForm from '../../../UI/FormReload'
import UIFormInputSelect from '../../../UI/FormReload/Input/Select'
import UIFormInputTextarea from '../../../UI/FormReload/Input/Textarea'

// Queries
import {
  PUT_INSPECTION_PHOTO,
  ADD_INSPECTION_ENTRY,
  LIST_SYSTEMS,
  LIST_SUB_SYSTEMS,
  SPEECH_TO_TEXT,
} from './queries'

import config from '../../../../config'

const { AWS } = config

const AudioRecorder = ({ id_inspection, onChange }) => {
  const [blob, setBlobState] = useState('')
  const mediaStream = useRef(null)
  const mediaRecorder = useRef(null)
  const chunks = useRef([])

  const [state, setStateState] = useState('inactive')

  const [speechToText] = useMutation(SPEECH_TO_TEXT, {
    fetchPolicy: 'no-cache',
    onSuccess: ({ data }) => {
      onChange(data.text)
    },
  })

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      mediaStream.current = stream
      mediaRecorder.current = new MediaRecorder(stream)
      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data)
        }
      }
      mediaRecorder.current.onstop = () => {
        setBlobState(new Blob(chunks.current, { type: 'audio/webm' }))
        chunks.current = []
      }
      mediaRecorder.current.start()
    } catch (error) {
      console.error('Error accessing microphone:', error)
    }
  }
  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.stop()
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop()
      })
    }
  }

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.pause()
      setStateState('paused')
    }
  }

  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'paused') {
      mediaRecorder.current.resume()
      setStateState('recording')
    }
  }

  const handleClick = () => {
    if (['inactive', 'recorded'].includes(state)) {
      startRecording()
      setStateState('recording')
    } else if (state === 'recording') {
      pauseRecording()
      setStateState('paused')
    } else if (state === 'paused') {
      resumeRecording()
      setStateState('recording')
    }
  }

  const handleStop = () => {
    stopRecording()
    setStateState('recorded')
  }

  const handleProccess = () => {
    // send recordedUrl to backend
    const file = new File([blob], 'audio.webm', { type: 'audio/webm' })

    speechToText({
      variables: {
        id_inspection,
        file,
      },
    })
    setStateState('inactive')
  }

  return (
    <Row className="mx-1 mt-2">
      {state === 'recorded' && blob && (
        <Col xs={12} className="my-2">
          <audio controls src={URL.createObjectURL(blob)} className="w-100" />
        </Col>
      )}
      <Col xs={12} className="my-1">
        <UIButton
          variant={
            ['inactive', 'recorded'].includes(state)
              ? 'danger'
              : state === 'paused'
              ? 'outline-stng'
              : 'stng'
          }
          className="m1 w-100"
          style={{ height: '50px' }}
          onClick={handleClick}
        >
          {['inactive', 'recorded'].includes(state)
            ? 'Grabar'
            : state === 'paused'
            ? 'Reanudar'
            : 'Pausar'}
        </UIButton>
      </Col>
      {!['inactive', 'recorded'].includes(state) && (
        <Col xs={12} className="my-1">
          <UIButton
            variant="success"
            className="m1 w-100"
            style={{ height: '50px' }}
            onClick={handleStop}
            disabled={state === 'recorded'}
          >
            Terminar
          </UIButton>
        </Col>
      )}
      {state === 'recorded' && (
        <Col xs={12} className="my-1">
          <UIButton
            variant="stng"
            className="m1 w-100"
            style={{ height: '50px' }}
            onClick={handleProccess}
          >
            Procesar
          </UIButton>
        </Col>
      )}
    </Row>
  )
}

const InspectionsFieldForm = ({ id_inspection }) => {
  const [{ me }] = useGlobal()

  const [idSystem, setIdSystem] = useState()
  const [photos, setPhotosState] = useState([])

  const [observation, setObservationState] = useState()

  const [triggerReset, setTriggerReset] = useTrigger()

  const alert = useAlert()

  const webcamRef = useRef(null)

  // TODO - On-click images add "delete" icon as watermark on top, marking it for deletion. Another click disable the deletion mark

  // --------------------------------------------------------------------------
  //
  // Query: Received samples info
  //
  // --------------------------------------------------------------------------

  // TODO - Query the photos from the inspections to be displayed

  const [addInspectonEntry] = useMutation(ADD_INSPECTION_ENTRY, {
    fetchPolicy: 'no-cache',
    onSuccess: () => {
      // Trigger reset
      setTriggerReset()
      setPhotosState([])
      alert('Inspección ingresada con éxito', { style: 'success' })
    },
  })

  const [putInspectionPhoto] = useMutation(PUT_INSPECTION_PHOTO, {
    fetchPolicy: 'no-cache',
    onSuccess: ({ data: { id, photo_name } }) => {
      setPhotosState((prev) => [...prev, { id, photo_name }])
    },
  })

  const capture = useCallback(() => {
    const photo = webcamRef.current.getScreenshot()
    putInspectionPhoto({ variables: { photo, id_inspection } })
  }, [webcamRef])

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  const handleSystemChange = (event) => {
    setIdSystem(parseInt(event.target.value) || undefined)
  }

  const handleSubmit = async ({
    observation,
    id_system,
    id_sub_system,
    criticality,
  }) => {
    await addInspectonEntry({
      variables: {
        id_inspection,
        observation,
        id_system,
        id_sub_system,
        criticality,
        photos: photos.map(({ id }) => id),
      },
    })
  }

  const handleObservation = (observation) => {
    setObservationState(observation)
  }

  const videoConstraints = {
    facingMode: 'environment',
  }

  return (
    <>
      <Row className="mb-4">
        <Col className="text-left">
          <Link
            className={`text-decoration-none text-stng-darker`}
            to={`/inspections/field`}
          >
            <UIButton className="mx-2 font-weight-light" onClick={() => {}}>
              Volver
            </UIButton>
          </Link>
        </Col>
      </Row>
      <UIPanelInfo>
        <UIPanelInfoBlock title="Nueva inspección">
          <UIForm onSubmit={handleSubmit} triggerReset={triggerReset}>
            <Webcam
              className="img-thumbnail"
              audio={false}
              width="100%"
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              ref={webcamRef}
              onClick={capture}
            />
            {photos.length > 0 && (
              <Row>
                {photos.map(({ id, photo_name }) => (
                  <Col xs={6} md={4}>
                    <Image
                      src={`https://s3-sa-east-1.amazonaws.com/${AWS.BUCKET}/${me.laboratory}/inspections/photos/${id_inspection}/${photo_name}`}
                      thumbnail
                    />
                  </Col>
                ))}
              </Row>
            )}
            <UIFormInputSelect
              label="Sistema"
              name="id_system"
              query={LIST_SYSTEMS}
              variables={{ id_inspection }}
              onChange={handleSystemChange}
              required
            >
              <option value={0}>Seleccione un sistema</option>
            </UIFormInputSelect>
            <UIFormInputSelect
              label="Sub-sistema"
              name="id_sub_system"
              query={LIST_SUB_SYSTEMS}
              variables={{ id_system: idSystem }}
              disabled={!idSystem}
            >
              <option value={0}>Seleccione un sub-sistema</option>
            </UIFormInputSelect>
            <UIFormInputSelect
              label="Criticidad"
              name="criticality"
              defaultValue="normal"
            >
              <option value="normal">Normal</option>
              <option value="alert">Alerta</option>
              <option value="critic">Crítico</option>
            </UIFormInputSelect>
            <UIFormInputTextarea
              label="Observaciones"
              name="observation"
              minRows={5}
              value={observation}
              onChange={(e) => setObservationState(e.target.value)}
            />
            <AudioRecorder
              id_inspection={id_inspection}
              onChange={handleObservation}
            />
            <UIPanelInfoSeparation className="my-1" />
            <div className="mr-2 text-center">
              <UIButton
                type="submit"
                variant="info"
                className="mr-2 px-3"
                title="Filtrar"
              >
                Crear inspección
              </UIButton>
            </div>
          </UIForm>
        </UIPanelInfoBlock>
      </UIPanelInfo>
    </>
  )
}

export default InspectionsFieldForm
